var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.student.requestIndex !== null && _vm.student.requestIndex > -1
        ? 'Edit student'
        : _vm.$t('bill.form.addStudent'),"visible":_vm.addStudentVisible,"width":"60%"},on:{"update:visible":function($event){_vm.addStudentVisible=$event}}},[_c('el-form',{attrs:{"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"學生"}},[(
            _vm.student.requestIndex !== null &&
              _vm.student.requestIndex > -1 &&
              _vm.student.student
          )?_c('div',[_vm._v(" "+_vm._s(_vm.userMethods.displayName( _vm.student.student.first_name, _vm.student.student.last_name ))+" ")]):_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":_vm.selectStudent},model:{value:(_vm.student.index),callback:function ($$v) {_vm.$set(_vm.student, "index", $$v)},expression:"student.index"}},_vm._l((_vm.allActiveStudents),function(user,index){return _c('el-option',{key:index,attrs:{"value":index,"label":`${user.name}(${user.email})`}})}),1)],1),(_vm.student.student_user_id > 0)?[_c('el-form-item',{attrs:{"label":"報名表"}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.student.student_class_ticket_id),callback:function ($$v) {_vm.$set(_vm.student, "student_class_ticket_id", $$v)},expression:"student.student_class_ticket_id"}},[_c('el-option',{attrs:{"value":null,"label":"請選擇對應報名表，如果沒有可以填寫下面信息后直接新增。"}}),_vm._l((_vm.student.student_class_tickets
                ? _vm.student.student_class_tickets
                : []),function(student_class_ticket,index){return _c('el-option',{key:index,attrs:{"value":student_class_ticket.id,"label":`(${_vm.moment(
                  student_class_ticket.enrolled_session.updated_at
                ).format('YYYY-MM-DD')}) ${_vm.helper.displayI18nText(
                  _vm.$i18n.locale,
                  student_class_ticket.enrolled_session.course_session.title
                )}`}})})],2)],1),[_c('el-form-item',{attrs:{"label":`線上${_vm.$t('column.tuition')} (/hr)`}},[_c('InputMoney',{attrs:{"money":isNaN(_vm.student.new_online_fee)
                  ? _vm.student.online_fee
                  : _vm.student.new_online_fee,"currency":_vm.student.new_online_fee_currency || _vm.student.online_fee_currency},on:{"changeMoney":online_fee => {
                  _vm.updateStudent({
                    new_online_fee: online_fee
                  });
                },"changeCurrency":online_fee_currency => {
                  _vm.updateStudent({
                    new_online_fee_currency: online_fee_currency
                  });
                }}})],1),_c('el-form-item',{attrs:{"label":`面對面${_vm.$t('column.tuition')} (/hr)`}},[_c('InputMoney',{attrs:{"money":isNaN(_vm.student.new_fee) ? _vm.student.fee : _vm.student.new_fee,"currency":_vm.student.new_fee_currency || _vm.student.fee_currency},on:{"changeMoney":fee => {
                  _vm.updateStudent({
                    new_fee: fee
                  });
                },"changeCurrency":currency => {
                  _vm.updateStudent({
                    new_fee_currency: currency
                  });
                }}})],1),_c('el-form-item',{attrs:{"label":"是否要預付學費"}},[_c('el-radio-group',{attrs:{"value":_vm.student.new_need_pay_first == null
                  ? _vm.student.need_pay_first
                  : _vm.student.new_need_pay_first},on:{"input":need_pay_first => {
                  _vm.updateStudent({
                    new_need_pay_first: need_pay_first
                  });
                }}},[_c('el-radio-button',{attrs:{"label":1}},[_vm._v("Yes")]),_c('el-radio-button',{attrs:{"label":0}},[_vm._v("No")])],1)],1),_c('el-form-item',{attrs:{"label":"預設收信家長"}},[(_vm.student.student.parents)?_c('el-checkbox-group',{model:{value:(_vm.student.default_parents_receivers),callback:function ($$v) {_vm.$set(_vm.student, "default_parents_receivers", $$v)},expression:"student.default_parents_receivers"}},_vm._l((_vm.student.student.parents
                  ? _vm.student.student.parents
                  : []),function(parent,index){return _c('el-checkbox',{key:index,attrs:{"label":parent.id}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( parent.first_name, parent.last_name )}(${parent.email})`)+" ")])}),1):_vm._e()],1)]]:_vm._e()],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.addStudentVisible = false}}},[_vm._v("取 消")]),(_vm.student.requestIndex !== null && _vm.student.requestIndex > -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateLessonRequestInfo(_vm.student.requestIndex)}}},[_vm._v(" 更 新 ")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addLessonRequest}},[_vm._v(" 儲 存 ")])],1)],1),_c('el-table',{key:_vm.isOnline,staticStyle:{"width":"100%"},attrs:{"data":_vm.lessonRequests}},[_c('el-table-column',{attrs:{"width":"150","label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'ProfileForAdmin',
            params: {
              id: scope.row.student_user_id
            }
          }}},[_vm._v(_vm._s(_vm.userMethods.displayName( scope.row.student.first_name, scope.row.student.last_name )))])]}}])}),[_c('el-table-column',{attrs:{"width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.addCurrencySymbol( scope.row.online_fee, scope.row.online_fee_currency ))+"/hr ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" "+_vm._s(`線上${_vm.$t("column.tuition")}`)+" "),(_vm.isOnline)?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("（主要學費）")]):_vm._e()])])],2),_c('el-table-column',{attrs:{"width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.addCurrencySymbol(scope.row.fee, scope.row.fee_currency))+"/hr ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" "+_vm._s(`面對面${_vm.$t("column.tuition")}`)+" "),(!_vm.isOnline)?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("（主要學費）")]):_vm._e()])])],2),_c('el-table-column',{attrs:{"label":"是否要預付學費"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.need_pay_first ? "Yes" : "No")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"預設收信家長"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.student.parents),function(parent){return _c('div',{key:parent.id,staticClass:"mb-2"},[_c('el-tag',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.default_parents_receivers.indexOf(parent.id) > -1
              ),expression:"\n                scope.row.default_parents_receivers.indexOf(parent.id) > -1\n              "}]},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( parent.first_name, parent.last_name )} (${parent.email})`)+" ")])],1)})}}])}),_c('el-table-column',{attrs:{"width":"100","label":_vm.$t('column.action')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.openEditStudent(scope.$index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{staticClass:"warning-icon",on:{"click":function($event){return _vm.removeStudent(scope.$index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]}}])})]],2),_c('div',{staticClass:"mt-3"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addNewStudent}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("bill.form.addStudent"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }