<template>
  <el-row :gutter="20">
    <el-col v-if="!isCounseling" :span="8">
      <el-radio-group
        :value="currentSubject"
        @input="
          value => {
            setValue({ subject: value, subject_type: 'Tutoring' });
          }
        "
      >
        <div><el-radio label="SAT">SAT</el-radio></div>
        <div><el-radio label="ACT">ACT</el-radio></div>
        <div><el-radio label="TOEFL">TOEFL</el-radio></div>
        <div><el-radio label="Math">Math</el-radio></div>
        <div><el-radio label="Reading">Reading</el-radio></div>
        <div><el-radio label="Writing">Writing</el-radio></div>
        <div>
          <el-radio label="TutoringOther">
            <el-input
              v-if="currentSubject === 'TutoringOther'"
              :value="subject"
              @input="
                value => {
                  setValue({ subject: value, subject_type: 'Tutoring' });
                }
              "
            />
            <span v-else>Other</span>
          </el-radio>
        </div>
      </el-radio-group>
    </el-col>
    <el-col v-if="isCounseling" :span="8">
      <el-radio-group
        :value="currentSubject"
        @input="
          value => {
            setValue({ subject: value, subject_type: 'Counseling' });
          }
        "
      >
        <div>
          <el-radio label="College counseling">College counseling</el-radio>
        </div>
        <div>
          <el-radio label="CounselingOther">
            <el-input
              v-if="currentSubject === 'CounselingOther'"
              :value="subject"
              @input="
                value => {
                  setValue({ subject: value, subject_type: 'Counseling' });
                }
              "
            />
            <span v-else>Other</span>
          </el-radio>
        </div>
      </el-radio-group>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Subject",
  props: {
    subject: String,
    subject_type: String,
    isCounseling: Boolean
  },
  data() {
    return {
      currentSubject: "",
      tutoring: ["SAT", "ACT", "TOEFL", "Math", "Reading", "Writing"],
      counseling: ["College counseling"]
    };
  },
  watch: {
    subject_type() {
      this.transferSubject();
    },
    subject() {
      this.transferSubject();
    }
  },
  created() {
    this.transferSubject();
  },
  methods: {
    transferSubject() {
      if (!this.subject_type || !this.subject) return;
      this.currentSubject = this.subject;

      if (!this[this.subject_type.toLowerCase()].includes(this.subject)) {
        this.currentSubject = `${this.subject_type}Other`;
        if (
          this.subject === "TutoringOther" ||
          this.subject === "CounselingOther"
        ) {
          this.setValue({ subject: "" });
        }
      }
    },
    setValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>

<style scoped></style>
