<template>
  <div class="container-fluid">
    <ClassTypeDialog
      :defaultIsOnline="privateLesson.is_online"
      :visible="classTypeDialogVisible"
      @submit="
        newIsOnline => {
          classTypeDialogVisible = false;
          setPrivateLesson({ is_online: newIsOnline });
        }
      "
      @toggleDialogVisible="() => {}"
    />
    <el-dialog
      title="請選擇要上第一堂課的老師："
      :visible.sync="displayChooseFirstTeachingTeacher"
      width="600px"
    >
      <el-select
        style="width: 100%"
        :value="firstLessonTeachingTeacher.teacher_user_id"
        @change="setFirstLessonTeachingTeacher"
      >
        <el-option
          :key="teacher.teacher_user_id"
          v-for="teacher in validTeachers"
          :value="teacher.teacher_user_id"
          :label="
            new_teacher
              ? userMethods.displayName(
                  teacher.new_teacher.first_name,
                  teacher.new_teacher.last_name
                )
              : ''
          "
        />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="createFirstLessonLog">
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <CheckMailPeopleDialog
      :display="displaySendMailInformation"
      :mailToStudents="$store.getters['privateLesson/mailToStudents']"
      :mailToParents="$store.getters['privateLesson/mailToParents']"
      :mailToTeachers="$store.getters['privateLesson/mailToTeachers']"
      :students="removeMarkedDeleteStudents"
      :teachers="$store.getters['privateLesson/needToIntroTeachers']"
      @onCancel="displaySendMailInformation = false"
      @onChangeMailToStudents="
        value => $store.commit('privateLesson/setMailToStudents', value)
      "
      @onChangeMailToParents="
        value => $store.commit('privateLesson/setMailToParents', value)
      "
      @onChangeMailToTeachers="
        value => $store.commit('privateLesson/setMailToTeachers', value)
      "
      @onSubmit="savePrivateLessonAndSendMail"
    />
    <Breadcrumb
      :customBreadcrumbList="[{ name: 'Home' }, { name: 'myClasses' }]"
    >
      <template slot="lastPage">
        Edit Lesson -&nbsp;<IsOnlineIcon
          :isOnline="privateLesson.is_online"
        />&nbsp;{{ pageTitle }}
      </template>
    </Breadcrumb>

    <h3 v-if="isEditing" class="header">
      Edit Lesson -&nbsp;
      <div>
        <IsOnlineIcon :isOnline="privateLesson.is_online" />&nbsp;{{
          pageTitle
        }}
      </div>
      <el-button
        v-if="!isCounseling"
        type="primary"
        @click="changeToCounseling"
      >
        把這堂課移到升學輔導
      </el-button>
    </h3>
    <h3 v-else>{{ pageTitle }}</h3>
    <hr class="separate-line" />
    <h4>上課方式</h4>
    <hr />
    <el-form label-position="right" label-width="150px" :model="privateLesson">
      <el-form-item label="上課方式">
        <el-radio-group
          :value="privateLesson.is_online"
          @input="is_online => setPrivateLesson({ is_online })"
        >
          <el-radio :label="1">Online</el-radio>
          <el-radio :label="0">In-person</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :required="!Number(privateLesson.is_online)"
        label="上課地點/資訊"
        prop="address"
      >
        <el-input
          type="textarea"
          :rows="2"
          :value="privateLesson.address"
          @input="
            address => {
              setPrivateLesson({ address });
              updateTheyWillBeMeetingAddress(address);
            }
          "
          :placeholder="Number(privateLesson.is_online) ? '網址' : '地址'"
        />
      </el-form-item>
    </el-form>
    <hr class="separate-line" />
    <h4>學生清單</h4>
    <hr />
    <AddStudentTable
      :subject_type="isCounseling ? 'counseling' : 'privateLessons'"
      :isOnline="Boolean(privateLesson.is_online)"
      :originalLessonRequests="removeMarkedDeleteStudents"
      @saveLessonRequest="setStudents"
    />
    <hr class="separate-line" />
    <h4>課程資訊</h4>
    <hr />
    <el-form
      ref="form"
      :model="privateLesson"
      label-position="right"
      label-width="200px"
    >
      <el-form-item
        required
        label="科目"
        prop="subject"
        :rules="[commonRules.radio, rules.otherSubject]"
      >
        <SubjectRadio
          :isCounseling="isCounseling"
          :subject="privateLesson.subject"
          :subject_type="privateLesson.subject_type"
          @change="
            ({ subject, subject_type }) =>
              setPrivateLesson({ subject, subject_type })
          "
        />
      </el-form-item>
      <el-form-item
        required
        label="課程名稱"
        :rules="commonRules.input"
        prop="title"
      >
        <template slot="label">
          課程名稱
          <el-tooltip
            content="請輸入一個比較明確且可分辨的課程名稱（如不要每堂課都叫 “SAT”，否則課程管理頁面會搞不清楚）"
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon"><i class="fas fa-question-circle"/></span>
          </el-tooltip>
        </template>
        <el-input
          :value="privateLesson.title"
          @input="title => setPrivateLesson({ title })"
        />
      </el-form-item>
      <el-form-item
        label="可上課時間、頻率及上多久"
        required
        prop="request_time_info"
        :rules="commonRules.input"
      >
        <div>
          <el-input
            type="textarea"
            :value="privateLesson.request_time_info"
            @input="
              request_time_info => setPrivateLesson({ request_time_info })
            "
            placeholder="如：“He’s based in Taiwan, and would like to have one class a week and 2 hours per class. He wishes to have classes on Saturday or Sunday mornings sometime between 9am-12pm Taiwan time).”"
          />
        </div>
      </el-form-item>
      <el-form-item label="上課頻率">
        <div>
          <div
            class="mb-3 d-flex"
            v-for="(month, index) in privateLesson.session_class_timeslots"
            :key="index"
          >
            <!-- <el-date-picker
              style="width: 500px;"
              v-model="range = [month.from, month.to]"
              type="monthrange"
              range-separator="~"
              start-placeholder="開始月份"
              end-placeholder="結束月份"
            >
            </el-date-picker> -->
            <el-select v-model="month.per" placeholder="Select">
              <el-option label="Multiple meetings" value="TBD">
                Multiple meetings
              </el-option>
              <el-option label="Week" value="W">
                Week
              </el-option>
              <el-option label="Month" value="M">
                Month
              </el-option>
            </el-select>
            <el-input
              v-if="month.per !== 'TBD'"
              class="ml-2"
              placeholder="次數"
              type="number"
              v-model="month.times"
              style="width: 200px;"
            >
              <template slot="append">次</template>
            </el-input>
            <el-date-picker
              class="ml-2"
              style="width: 200px;"
              v-model="month.from"
              type="date"
              placeholder="開始日期"
            >
            </el-date-picker>
            <el-date-picker
              class="ml-2"
              style="width: 200px;"
              v-model="month.to"
              type="date"
              placeholder="結束日期"
            >
            </el-date-picker>
            <el-button
              class="ml-2"
              size="small"
              type="danger"
              @click="privateLesson.session_class_timeslots.splice(index, 1)"
            >
              <i class="fa fa-times"></i>
            </el-button>
          </div>
          <el-button
            class="mt-2"
            size="small"
            type="primary"
            @click="
              privateLesson.session_class_timeslots.push({
                from: null,
                to: null,
                times: 2,
                per: 'M'
              })
            "
          >
            <i class="fa fa-plus"></i>
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="上課日期" prop="started_on">
        <el-date-picker
          :value="privateLesson.started_date"
          @input="started_date => setPrivateLesson({ started_date })"
          type="date"
          placeholder="日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上課時間" prop="time_from">
        <el-time-select
          :value="privateLesson.started_time"
          @input="started_time => setPrivateLesson({ started_time })"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '23:30'
          }"
          placeholder="開始時間"
        >
        </el-time-select>
      </el-form-item>
      <el-form-item
        label="上課需求"
        required
        prop="request_content"
        :rules="commonRules.input"
      >
        <el-input
          type="textarea"
          :value="privateLesson.request_content"
          @input="request_content => setPrivateLesson({ request_content })"
          placeholder="給老師的詳細介紹，如：“The student has never taken SAT classes before. He scored 1380 (580 reading, 800 math) on our mock test, so he only needs to work on the English section. He said can spend at least 1 hour on studying, so you should assign him more homework.”"
        />
      </el-form-item>
      <el-form-item label="希望聯繫方式">
        <el-radio-group
          :value="privateLesson.contact_tool_preference"
          @input="
            contact_tool_preference =>
              setPrivateLesson({ contact_tool_preference })
          "
        >
          <el-radio :label="1" border>Email</el-radio>
          <el-radio :label="2" border>Phone</el-radio>
          <el-radio :label="3" border>Line</el-radio>
          <el-radio :label="4" border>Skype</el-radio>
          <el-radio :label="5" border>Wechat</el-radio>
          <el-radio :label="6" border>FaceTime</el-radio>
        </el-radio-group>
      </el-form-item>
      <Note
        :admin_note="privateLesson.admin_note"
        :teacher_note="privateLesson.teacher_note"
        @change="data => setPrivateLesson(data)"
      />
    </el-form>
    <hr class="separate-line" />
    <h4>老師</h4>
    <hr />
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <b>已確認</b>
      </div>
      <AddTeacherTable
        :isOnline="Boolean(privateLesson.is_online)"
        :originalTeachers="removeMarkedDeleteTeachers"
        @saveTeachers="
          teachers => {
            $store.dispatch('privateLesson/setTeachers', teachers);
          }
        "
        @addTeacher="
          teachers => {
            this.$confirm(
              '課程關閉邀請老師後，不會出現在 Lesson Requests',
              '是否關閉邀請老師？',
              {
                confirmButtonText: '關閉邀請老師',
                cancelButtonText: '開放邀請老師',
                type: 'warning'
              }
            ).then(() => {
              this.setPrivateLesson({ look_for_teacher: 0 });
            }).catch(() => {
              this.setPrivateLesson({ look_for_teacher: 1 });
            });
          }
        "
        @markIsNewTeacher="
          ({ isNewTeacher, teacherId }) =>
            $store.dispatch('privateLesson/markIsNewTeacher', {
              isNewTeacher,
              teacherId
            })
        "
      />
      <br />
      <el-form
        label-position="right"
        label-width="80px"
        v-if="
          removeMarkedDeleteTeachers && removeMarkedDeleteTeachers.length > 0
        "
      >
        <el-form-item label="第一堂課">
          <template v-if="hasLessonLogs">
            已安排：
            <a
              :href="
                `/private/private-lessons/${$route.params.class_id}/detail`
              "
            >
              <!-- {{ scope.row.subject }} -->
              {{
                instant.formatGoogleTime(
                  classLessonLogs[classLessonLogs.length - 1].started_at
                )
              }}
            </a>
          </template>
          <template v-else>
            <el-radio-group v-model="isDecidedStartTime">
              <el-radio-button :label="false">待定</el-radio-button>
              <el-radio-button :label="true">已安排</el-radio-button>
            </el-radio-group>
            <template v-if="isDecidedStartTime">
              <span>：</span>
              <el-date-picker
                value-format="yyyy-MM-dd"
                :value="privateLesson.started_date"
                @input="started_date => setPrivateLesson({ started_date })"
                type="date"
                placeholder="日期"
                style="width: 150px"
              />
              <el-time-select
                :value="privateLesson.started_time"
                @input="started_time => setPrivateLesson({ started_time })"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '23:45'
                }"
                placeholder="時間"
                style="width: 140px"
              />
              <TimezoneSelector
                :value="privateLesson.timezone"
                @change="timezone => setPrivateLesson({ timezone })"
              />
              for
              <el-input-number
                style="width: 100px"
                controls-position="right"
                :value="privateLesson.duration_hours"
                @change="duration_hours => setPrivateLesson({ duration_hours })"
              />
              hour(s)
              <MinutesInput
                size="large"
                width="100"
                :value="privateLesson.duration_minutes"
                :change="
                  duration_minutes => setPrivateLesson({ duration_minutes })
                "
              />
              min(s)
              <el-alert
                class="thatWillCreateFirstLessonLog"
                title="會新增第一堂課的 Lesson Log"
                type="warning"
                show-icon
                :closable="false"
              />
            </template>
          </template>
        </el-form-item>
      </el-form>
    </el-card>
    <br />
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <b>未確認</b>
      </div>
      <el-form label-position="right">
        <el-form-item label="開放邀請更多老師">
          <el-radio-group
            :value="privateLesson.look_for_teacher"
            @input="look_for_teacher => setPrivateLesson({ look_for_teacher })"
          >
            <el-radio-button :label="1">是</el-radio-button>
            <el-radio-button :label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="privateLesson.look_for_teacher">
          <h6>
            已邀請老師
            <span class="action-icon" @click="savePrivateLessonAndInvitation">
              <i class="el-icon-plus" />
            </span>
          </h6>
          <el-form-item>
            <el-table
              :data="$store.getters['privateLesson/notYetJoinedClassTeachers']"
            >
              <el-table-column label="#" type="index" />
              <el-table-column label="Teacher">
                <template slot-scope="scope">
                  <router-link
                    :to="{
                      name: 'ProfileForAdmin',
                      params: { id: scope.row.teacher_user_id }
                    }"
                  >
                    {{
                      userMethods.displayName(
                        scope.row.teacher_user.first_name,
                        scope.row.teacher_user.last_name
                      )
                    }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column width="60" label="No Reply">
                <template slot="header">
                  <el-tooltip
                    content="No Reply"
                    placement="top"
                    popper-class="tooltipColor"
                  >
                    <span class="action-icon" style="color: grey;">
                      <i class="el-icon-question" />
                    </span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span v-if="scope.row.isEditing">
                    <el-radio
                      :label="0"
                      :value="scope.row.editInviationStatus"
                      @input="
                        editInviationStatus =>
                          setInvitationTeacher(scope.row.id, {
                            editInviationStatus
                          })
                      "
                      >{{ "" }}</el-radio
                    >
                  </span>
                  <span v-else style="color:grey;">
                    <i
                      v-if="scope.row.replied_status === 0"
                      class="el-icon-question"
                    />
                  </span>
                </template>
              </el-table-column>
              <el-table-column width="60" align="center">
                <template slot="header">
                  <el-tooltip
                    content="Accepted"
                    placement="top"
                    popper-class="tooltipColor"
                  >
                    <span class="action-icon">
                      <i class="el-icon-success" />
                    </span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span v-if="scope.row.isEditing">
                    <el-radio
                      :label="1"
                      :value="scope.row.editInviationStatus"
                      @input="
                        editInviationStatus =>
                          setInvitationTeacher(scope.row.id, {
                            editInviationStatus
                          })
                      "
                      >{{ "" }}</el-radio
                    >
                  </span>
                  <span v-else style="color:forestgreen;">
                    <i
                      v-if="scope.row.replied_status === 1"
                      class="el-icon-success"
                    />
                  </span>
                </template>
              </el-table-column>
              <el-table-column width="60" label="Rejected" align="center">
                <template slot="header">
                  <el-tooltip
                    content="Rejected"
                    placement="top"
                    popper-class="tooltipColor"
                  >
                    <span class="action-icon" style="color: indianred;">
                      <i class="el-icon-error" />
                    </span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span v-if="scope.row.isEditing">
                    <el-radio
                      :label="2"
                      :value="scope.row.editInviationStatus"
                      @input="
                        editInviationStatus =>
                          setInvitationTeacher(scope.row.id, {
                            editInviationStatus
                          })
                      "
                      >{{ "" }}</el-radio
                    >
                  </span>
                  <span v-else style="color: indianred;">
                    <i
                      v-if="scope.row.replied_status === 2"
                      class="el-icon-error"
                    />
                  </span>
                </template>
              </el-table-column>
              <el-table-column width="250" label="Salary">
                <template slot-scope="scope">
                  <InputMoney
                    v-if="scope.row.isEditing"
                    :money="scope.row.editSalary"
                    :currency="scope.row.editCurrency"
                    @changeMoney="
                      salary =>
                        setInvitationTeacher(scope.row.id, {
                          editSalary: salary
                        })
                    "
                    @changeCurrency="
                      currency =>
                        setInvitationTeacher(scope.row.id, {
                          editCurrency: currency
                        })
                    "
                  />
                  <span v-else>
                    {{
                      addCurrencySymbol(
                        privateLesson.is_online
                          ? scope.row.online_salary
                          : scope.row.salary,
                        privateLesson.is_online
                          ? scope.row.online_salary_currency
                          : scope.row.currency
                      )
                    }}/hr
                  </span>
                </template>
              </el-table-column>
              <el-table-column width="175" label="Availability">
                <template slot-scope="scope">
                  {{ scope.row.replied_note }}
                </template>
              </el-table-column>
              <el-table-column width="175" label="Note">
                <template slot-scope="scope">
                  <el-input
                    v-if="scope.row.isEditing"
                    :value="scope.row.editNotes"
                    @input="
                      editNotes =>
                        setInvitationTeacher(scope.row.id, { editNotes })
                    "
                  />
                  <span v-else>{{ scope.row.notes }}</span>
                </template>
              </el-table-column>
              <el-table-column width="120" label="Invitation Date">
                <template slot-scope="scope">
                  {{ instant.formatGoogleTime(scope.row.created_at) }}
                </template>
              </el-table-column>
              <el-table-column width="120" label="操作">
                <template slot-scope="scope">
                  <template v-if="scope.row.isEditing">
                    <el-tooltip
                      :value="true"
                      :content="$t('message.remeberSave')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <span
                        key="check"
                        class="action-icon"
                        @click="() => saveInvitation(scope.row)"
                      >
                        <i class="fas fa-check" />
                      </span>
                    </el-tooltip>
                    <span
                      key="times"
                      class="warning-icon"
                      @click="() => switchInvitationEdit(scope.row.id, false)"
                    >
                      <i class="fas fa-times" />
                    </span>
                  </template>
                  <template v-else>
                    <span
                      class="action-icon"
                      @click="() => sendInvitationToTeacher(scope.row.id)"
                    >
                      <i class="far fa-envelope" />
                    </span>
                    <span
                      class="action-icon"
                      @click="() => switchInvitationEdit(scope.row.id, true)"
                    >
                      <i class="fas fa-edit" />
                    </span>
                    <span
                      v-if="scope.row.replied_status === 0"
                      class="warning-icon"
                      @click="() => deleteTeacherInvitation(scope.row.id)"
                    >
                      <i class="fas fa-trash-alt" />
                    </span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="180" label="確認老師">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="
                      () =>
                        saveAndAddInvitationTeacherToClass(
                          scope.row.id,
                          scope.row.teacher_user.first_name
                        )
                    "
                  >
                    {{ `${scope.row.isEditing ? "儲存並" : ""}確認老師` }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </el-form>
    </el-card>
    <br />
    <el-row>
      <el-col align="center">
        <el-button
          v-if="hasNewTeacher"
          :type="isWantToSendMail ? 'primary' : ''"
          @click="isWantToSendMail = true"
        >
          儲存並發送新老師通知信
        </el-button>
        <el-button
          @click="
            () => {
              $store.commit('privateLesson/setMailToTeachers', 0);
              $store.commit('privateLesson/setMailToStudents', 0);
              $store.commit('privateLesson/setMailToParents', 0);
              savePrivateLesson();
            }
          "
        >
          儲存資料
        </el-button>
      </el-col>
    </el-row>
    <div v-if="isWantToSendMail && hasNewTeacher">
      <hr class="separate-line" />
      <h4>老師介紹</h4>
      <hr />
      <div
        :key="new_teacher.id"
        v-for="{ new_teacher, is_update_teacher_bio } in $store.getters[
          'privateLesson/needToIntroTeachers'
        ]"
      >
        <h5>
          {{
            `${userMethods.displayName(
              new_teacher.first_name,
              new_teacher.last_name
            )} 的教師介紹：`
          }}
        </h5>
        <el-input
          type="textarea"
          :rows="3"
          :value="getTeacherBio(new_teacher)"
          @input="value => setTeacherBio(new_teacher.id, value)"
        />
        <el-form class="mt-3" label-position="right" label-width="150px">
          <el-form-item label="是否更新教師資料：">
            <el-radio-group
              :value="is_update_teacher_bio"
              @input="value => setIsUpdateTeacherBio(new_teacher.id, value)"
            >
              <el-radio-button :label="true">
                是
              </el-radio-button>
              <el-radio-button :label="false">
                否
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <hr class="separate-line" />
      <h4>師生見面會資訊</h4>
      <hr />
      <el-form label-position="right" label-width="170px">
        <el-form-item label="是否舉辦師生見面會：">
          <el-radio-group
            :value="privateLesson.has_meeting"
            @input="has_meeting => setPrivateLesson({ has_meeting })"
          >
            <el-radio-button :label="true">
              是
            </el-radio-button>
            <el-radio-button :label="false">
              否
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="見面會：" v-if="privateLesson.has_meeting">
          <template>
            <el-card
              class="mt-3"
              :key="index"
              v-for="(theyWillBeMeeting,
              index) in privateLesson.they_will_be_meetings"
            >
              <div slot="header" class="clearfix">
                <span>{{ `師生見面會 ${index + 1}` }}</span>
                <el-button
                  v-if="index !== 0"
                  type="text"
                  style="float: right; padding: 3px 0"
                  @click="
                    () =>
                      $store.dispatch(
                        'privateLesson/removeTheyWillBeMeetings',
                        index
                      )
                  "
                >
                  <span class="text-danger">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </el-button>
              </div>
              <!-- <h5>
                {{ `師生見面會 ${index + 1}` }}
                <span
                  v-if="index !== 0"
                  class="warning-icon"
                  @click="
                    () =>
                      $store.dispatch(
                        'privateLesson/removeTheyWillBeMeetings',
                        index
                      )
                  "
                >
                  <i class="fas fa-trash-alt" />
                </span>
              </h5> -->
              <GreetMeetingForm
                :teachers="validTeachers"
                :theyWillBeMeeting="theyWillBeMeeting"
                :privateLessonAddress="privateLesson.address"
                @setTheyWillBeMeeting="
                  value => setTheyWillBeMeeting(index, value)
                "
              />
            </el-card>
            <br />
            <el-button type="primary" @click="addTheyWillBeMeeting">
              新增更多
            </el-button>
          </template>
        </el-form-item>
      </el-form>
      <hr class="separate-line" />
      <h2>信件預覽</h2>
      <el-button
        type="primary"
        style="margin-bottom: 8px"
        @click="displayMailPreviewer = true"
      >
        開啟信件預覽
      </el-button>
      <div v-show="displayMailPreviewer">
        <MailPreviewer
          @setIntroNewTeacherMailContent="
            mailContent => {
              $store.commit(
                'privateLesson/setIntroNewTeacherMailContent',
                mailContent
              );
            }
          "
          :isCreateClass="!isEditing"
          :id="$route.params.class_id"
          :students="$store.getters['privateLesson/validStudents']"
          :isOnline="privateLesson.is_online"
          :subject="privateLesson.subject"
          :teachers="validTeachers"
          :newTeachers="$store.getters['privateLesson/needToIntroTeachers']"
          :bioType="bioType"
          :hasMeeting="privateLesson.has_meeting"
          :theyWillBeMeetings="privateLesson.they_will_be_meetings"
          :isDecidedStartTime="isDecidedStartTime"
          :startedTimezone="privateLesson.timezone"
          :startedDate="privateLesson.started_date"
          :startedTime="privateLesson.started_time"
          :address="privateLesson.address"
        />
      </div>
      <el-row>
        <el-col align="center">
          <el-button @click="checkIfStudentIncludeTempMail">
            儲存並發送新老師通知信
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import ClassTypeDialog from "@/components/privateLesson/ClassTypeDialog";
import MinutesInput from "@/components/privateLesson/MinutesInput";
import GreetMeetingForm from "@/views/private/GreetMeeting/GreetMeetingForm";
import AddStudentTable from "@/views/private/Form/AddStudentTable";
import AddTeacherTable from "@/views/private/Form/AddTeacherTable";
import SubjectRadio from "@/views/private/Form/SubjectRadio";
import CheckMailPeopleDialog from "@/views/private/Form/CheckMailPeopleDialog";
import Note from "@/views/private/Form/Note";
import { InputMoney } from "@/components/input";
import { TimezoneSelector } from "@/components/selector";
import MailPreviewer from "./MailPreviewer";
import formMixin from "@/mixins/form";
import moneyMixin from "@/mixins/money";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  watch: {
    isDecidedStartTime() {
      if (!this.isEditing) {
        if (this.isDecidedStartTime) {
          this.$store.commit("privateLesson/setPrivateLesson", {
            started_date: moment(new Date()).format("YYYY-MM-DD"),
            started_time: "08:00"
          });
        } else {
          this.$store.commit("privateLesson/setPrivateLesson", {
            started_date: null,
            started_time: null
          });
        }
      }
    }
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    ClassTypeDialog,
    MinutesInput,
    AddStudentTable,
    AddTeacherTable,
    SubjectRadio,
    Note,
    InputMoney,
    TimezoneSelector,
    MailPreviewer,
    GreetMeetingForm,
    CheckMailPeopleDialog
  },
  data() {
    return {
      times: 0,
      isDecidedStartTime: false,
      isWantToSendMail: false,
      displayMailPreviewer: false,
      displaySendMailInformation: false,
      displayChooseFirstTeachingTeacher: false,
      firstLessonTeachingTeacher: {},
      classTypeDialogVisible: true,
      studentClassTickets: [],
      classLessonLogs: []
    };
  },
  mixins: [formMixin, moneyMixin],
  computed: {
    hasLessonLogs() {
      return this.classLessonLogs && this.classLessonLogs.length > 0;
    },
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    ACCPECTED() {
      return 1;
    },
    REJECTED() {
      return 2;
    },
    isEditing() {
      return (
        this.$route.name === "privateClassesEdit" ||
        this.$route.name === "privateLessonLinkTeacher"
      );
    },
    pageTitle() {
      if (this.isEditing) {
        return this.privateLesson.title;
      } else {
        return this.$t("pageTitle.addPrivateLessons");
      }
    },
    privateLesson() {
      return this.$store.getters["privateLesson/privateLesson"];
    },
    validTeachers() {
      return this.$store.getters["privateLesson/validTeachers"];
    },
    removeMarkedDeleteStudents() {
      return this.removeMarkedDeleteUser(this.privateLesson.lesson_requests);
    },
    removeMarkedDeleteTeachers() {
      return this.removeMarkedDeleteUser(this.privateLesson.teachers);
    },
    hasNewTeacher() {
      return (
        this.$store.getters["privateLesson/needToIntroTeachers"].length !== 0
      );
    },
    needToCreateFirstLessonLog() {
      return (
        this.isDecidedStartTime &&
        !this.$store.getters["privateLesson/haveNoTeacher"]
      );
    },
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    bioType() {
      if (this.isCounseling) {
        return "counseling_bio";
      } else {
        return "tutoring_bio";
      }
    },
    rules() {
      const validOtherSubject = (rule, value, callback) => {
        let invalid = false;
        if (!this.privateLesson.subject) {
          invalid = true;
        }

        if (invalid) {
          callback(new Error());
          return;
        }
        callback();
      };

      return {
        otherSubject: {
          validator: validOtherSubject,
          message: this.$t("validation.required"),
          trigger: "blur"
        },
        address: {
          validator: (rule, value, callback) => {
            let invalid = false;
            if (Number(this.privateLesson.is_online)) {
              invalid = this.privateLesson.address === "";
            }

            if (invalid) {
              callback(new Error());
              return;
            }
            callback();
          }
        }
      };
    }
  },
  async created() {
    this.$store.commit("privateLesson/initState");
    if (this.isEditing) {
      this.classTypeDialogVisible = false;
      await this.$store.dispatch(
        "privateLesson/getPrivateLesson",
        this.$route.params.class_id
      );
      // console.log(res);
      this.isDecidedStartTime =
        this.privateLesson.started_date !== null &&
        this.privateLesson.started_time !== null;
      this.studentClassTickets = this.privateLesson.student_class_tickets;
      this.classLessonLogs = this.privateLesson.lesson_logs;
    }

    if (this.$route.name === "privateLessonLinkTeacher") {
      document.querySelector("#teachers").scrollIntoView(true);
    }
  },
  methods: {
    getTeacherBio(teacher) {
      if (typeof teacher[this.bioType] === "string") {
        return JSON.parse(teacher[this.bioType]).en;
      }
      return teacher[this.bioType].en;
    },
    setIsUpdateTeacherBio(teacherId, is_update_teacher_bio) {
      this.$store.dispatch("privateLesson/setIsUpdateTeacherBio", {
        teacherId,
        is_update_teacher_bio
      });
    },
    setTeacherBio(teacherId, bio) {
      this.$store.dispatch("privateLesson/setTeacherBio", {
        teacherId,
        bio,
        bioType: this.bioType
      });
    },
    removeMarkedDeleteUser(users) {
      return users.filter(user => user.action_mode !== "D");
    },
    setPrivateLesson(privateLesson) {
      console.log(privateLesson);
      if (Object.keys(privateLesson).includes("is_online")) {
        this.$store.commit("privateLesson/setPrivateLesson", {
          ...privateLesson,
          address: Number(privateLesson.is_online) ? "" : privateLesson.address
        });
      } else {
        this.$store.commit("privateLesson/setPrivateLesson", {
          ...privateLesson
        });
      }
      if (
        Object.keys(privateLesson).includes("subject") ||
        Object.keys(privateLesson).includes("subject_type")
      ) {
        this.setPrivateLessonTitle();
      }
    },
    setStudents(students) {
      if (this.$route.query.enrolled_session_id) {
        students = students.map(student => ({
          ...student,
          enrolled_session_id: this.$route.query.enrolled_session_id
        }));
      }
      this.$store.dispatch("privateLesson/setStudents", students);
      this.setPrivateLessonTitle();
    },
    async changeToCounseling() {
      await this.$store.dispatch(
        "privateLesson/changeToCounseling",
        this.$route.params.class_id
      );
      this.$router.push({ name: "privateLessons" });
    },
    updateTheyWillBeMeetingAddress(address) {
      this.privateLesson.they_will_be_meetings.forEach(
        (they_will_be_meeting, index) => {
          if (they_will_be_meeting.isSameOfClassAddress) {
            this.setTheyWillBeMeeting(index, { text: address });
          }
        }
      );
    },
    async sendInvitationToTeacher(invitationId) {
      try {
        await this.$confirm("確定要寄送邀請信嗎？", this.$t("message.notice"), {
          confirmButtonText: this.$t("message.continue"),
          cancelButtonText: this.$t("message.cancel"),
          type: "warning"
        });
      } catch (e) {
        return false;
      }

      this.$store.dispatch(
        "privateLesson/sendInvitationToTeacher",
        invitationId
      );
    },
    switchInvitationEdit(invitationId, status) {
      this.$store.dispatch("privateLesson/switchInvitationEdit", {
        invitationId,
        status
      });
    },
    setInvitationTeacher(invitationId, data) {
      this.$store.dispatch("privateLesson/setInvitationTeacher", {
        invitationId,
        data
      });
    },
    async saveInvitation(invitation) {
      await this.$store.dispatch(
        "privateLesson/saveInvitationTeacher",
        invitation.id
      );
    },
    async deleteTeacherInvitation(invitationId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      this.$store.dispatch(
        "privateLesson/deleteTeacherInvitation",
        invitationId
      );
    },
    async saveAndAddInvitationTeacherToClass(invitationId, teacherFirstName) {
      await this.$store.dispatch(
        "privateLesson/addInvitationTeacherToClass",
        invitationId
      );
      await this.replyInvitationTeacher(invitationId, this.ACCPECTED);

      try {
        await this.$confirm(
          `已添加 ${teacherFirstName} 為老師，是否還有其他老師要添加？`,
          this.$t("message.notice"),
          {
            confirmButtonText: "安排更多老師",
            cancelButtonText: "無，已完成老師安排",
            type: "warning"
          }
        );
      } catch (e) {
        this.setPrivateLesson({ look_for_teacher: 0 });
      }
    },
    async replyInvitationTeacher(invitationId, replyStatus) {
      this.switchInvitationEdit(invitationId, true);
      this.setInvitationTeacher(invitationId, {
        editInviationStatus: replyStatus
      });
      await this.$store.dispatch(
        "privateLesson/saveInvitationTeacher",
        invitationId
      );
    },
    createGreetMeetings(privateLessonId) {
      this.$store.dispatch("privateLesson/createGreetMeetings", {
        classId: privateLessonId,
        classAddress: this.privateLesson.address,
        theyWillBeMeetings: this.privateLesson.they_will_be_meetings
      });
    },
    async savePrivateLesson(callback = null) {
      const valid = await this.checkFormDataIsValid();
      if (!valid) return;
      if (this.isEditing) {
        this.$store.dispatch(
          "privateLesson/updatePrivateLesson",
          privateLessonId => {
            this.createGreetMeetings(privateLessonId);
            if (callback) {
              callback(privateLessonId);
            } else {
              this.$message({
                message: "Success!",
                type: "success"
              });
              this.$router.go(-1);
            }
          }
        );
        if (
          this.classLessonLogs &&
          this.classLessonLogs.length < 1 &&
          this.needToCreateFirstLessonLog
        ) {
          this.checkFirstTeacherAndCreateFirstLessonLog();
        }
      } else {
        this.$store.dispatch(
          "privateLesson/createPrivateLesson",
          (privateLessonId, studentClassTickets) => {
            this.createGreetMeetings(privateLessonId);
            if (this.needToCreateFirstLessonLog) {
              this.studentClassTickets = studentClassTickets;
              this.checkFirstTeacherAndCreateFirstLessonLog();
            } else {
              if (callback) {
                callback(privateLessonId);
              } else {
                this.$router.push({
                  name: "myClasses"
                });
              }
            }
          }
        );
      }

      if (this.isWantToSendMail) {
        this.updateTeacherBio();
      }

      this.updateTeacherSalary();
    },
    setFirstLessonTeachingTeacher(teacherUserId) {
      this.firstLessonTeachingTeacher = this.validTeachers.find(
        ({ teacher_user_id }) => teacher_user_id === teacherUserId
      );
    },
    checkFirstTeacherAndCreateFirstLessonLog() {
      this.firstLessonTeachingTeacher = this.validTeachers[0];
      if (1 < this.validTeachers.length) {
        this.displayChooseFirstTeachingTeacher = true;
      } else {
        this.createFirstLessonLog();
      }
    },
    createFirstLessonLog() {
      this.$store.dispatch("lessonLog/createPrivateScheduleLessonLog", {
        privateScheduleLesson: {
          classId: this.studentClassTickets[0].class_id,
          teacherUserId: this.firstLessonTeachingTeacher.teacher_user_id,
          salary: this.privateLesson.is_online
            ? this.firstLessonTeachingTeacher.online_salary
            : this.firstLessonTeachingTeacher.salary,
          salaryCurrency: this.privateLesson.is_online
            ? this.firstLessonTeachingTeacher.online_salary_currency
            : this.firstLessonTeachingTeacher.salary_currency,
          studentClassTickets: this.studentClassTickets.map(
            studentClassTicket => ({
              id: studentClassTicket.id,
              fee: this.privateLesson.is_online
                ? studentClassTicket.online_fee
                : studentClassTicket.fee,
              fee_currency: this.privateLesson.is_online
                ? studentClassTicket.online_fee_currency
                : studentClassTicket.fee_currency
            })
          ),
          schedules: [
            {
              started_at: `${this.privateLesson.started_date} ${this.privateLesson.started_time}`,
              timezone: this.privateLesson.timezone,
              duration_hours: this.privateLesson.duration_hours,
              duration_minutes: this.privateLesson.duration_minutes
            }
          ]
        }
      });

      this.$router.push({
        name: "myClasses"
      });
    },
    async updateTeacherBio() {
      const updateTeacherBioPromises = [];
      this.privateLesson.teachers.forEach(teacher => {
        if (teacher.is_update_teacher_bio) {
          updateTeacherBioPromises.push(
            this.$store.dispatch("profile/updateTeacherProfileByAdmin", {
              id: teacher.new_teacher_user_id,
              newProfile: teacher.new_teacher
            })
          );
        }
      });
      await Promise.all(updateTeacherBioPromises);
    },
    async updateTeacherSalary() {
      const updateTeacherSalaryPromises = [];
      this.privateLesson.teachers.forEach(teacher => {
        if (teacher.is_update_teacher_salary) {
          updateTeacherSalaryPromises.push(
            this.$store.dispatch("profile/updateTeacherProfileByAdmin", {
              id: teacher.new_teacher_user_id,
              newProfile: {
                in_person_salary: teacher.salary,
                in_person_currency: teacher.salary_currency,
                online_salary: teacher.online_salary,
                online_currency: teacher.online_salary_currency
              }
            })
          );
        }
      });
      await Promise.all(updateTeacherSalaryPromises);
    },
    async savePrivateLessonAndInvitation() {
      const routeToInvitation = privateLessonId => {
        this.$router.push({
          name: "privateLessonInvitation",
          params: { id: privateLessonId }
        });
      };
      this.savePrivateLesson(routeToInvitation);
    },
    async savePrivateLessonAndSendMail() {
      this.displayMailPreviewer = true;
      this.savePrivateLesson();
    },
    async checkFormDataIsValid() {
      let valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return valid;
      }

      // if (this.$store.getters["privateLesson/hasEditingStudents"]) {
      //   this.$message.error("請先儲存還在修改的學生！");
      //   return false;
      // }

      if (this.$store.getters["privateLesson/hasEditingTeachers"]) {
        this.$message.error("請先儲存還在修改的老師！");
        return false;
      }

      if (
        this.privateLesson.look_for_teacher &&
        this.$store.getters["privateLesson/hasEditingTeacherInvitations"]
      ) {
        this.$message.error("請先儲存還在修改的老師邀請！");
        return false;
      }

      return valid;
    },
    addTheyWillBeMeeting() {
      this.$store.dispatch("privateLesson/addTheyWillBeMeeting");
    },
    setTheyWillBeMeeting(theyWillBeMeetingIndex, data) {
      this.$store.dispatch("privateLesson/setTheyWillBeMeeting", {
        theyWillBeMeetingIndex,
        data
      });
    },
    setPrivateLessonTitle() {
      let studentFirstNames = "";
      const validStudents = this.privateLesson.lesson_requests.filter(
        ({ student, new_student }) => student || new_student
      );
      if (0 < validStudents.length && validStudents.length < 3) {
        validStudents.forEach(({ student, new_student }) => {
          const mainStudent = new_student || student;
          if (studentFirstNames === "") {
            studentFirstNames += `${mainStudent.first_name}`;
          } else {
            studentFirstNames += ` and ${mainStudent.first_name}`;
          }
        });
        studentFirstNames += "'s ";
      }
      this.setPrivateLesson({
        title: `${studentFirstNames}${this.privateLesson.subject || ""}`
      });
    },
    checkIfStudentIncludeTempMail() {
      const studentsIncludeTempMail = this.removeMarkedDeleteUser(
        this.privateLesson.lesson_requests
      ).filter(({ student, new_student }) =>
        ((new_student || student).email || "").startsWith("IVYWAY_TEMP")
      );
      if (studentsIncludeTempMail.length > 0) {
        let studentsListHTML = "";
        studentsIncludeTempMail.forEach(({ student }) => {
          studentsListHTML += `<li>First Name: ${student.first_name}<br />Email: ${student.email}</li>`;
        });
        this.$alert(
          `以下學生尚未更新信箱，請協助學生更新信箱，否則他們會收不到通知信！<ul>${studentsListHTML}</ul>`,
          this.$t("message.notice"),
          {
            type: "warning",
            confirmButtonText: "回上一步",
            dangerouslyUseHTMLString: true
          }
        );
      } else {
        this.showSendMailInformation();
      }
    },
    showSendMailInformation() {
      this.$store.commit("privateLesson/setMailToTeachers", 1);
      this.$store.commit("privateLesson/setMailToStudents", 1);
      this.$store.commit("privateLesson/setMailToParents", 1);
      this.displaySendMailInformation = true;
    }
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 20px;
  max-width: 95vw;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header > button {
  margin-left: 8px;
}

.thatWillCreateFirstLessonLog {
  margin-top: 20px;
}
</style>
