var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{key:_vm.isOnline,staticStyle:{"width":"100%"},attrs:{"data":_vm.attachAddButton(_vm.teachers),"border":""}},[_c('el-table-column',{attrs:{"width":"100","label":"是否發送配對成功信"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 'addItem')?_c('el-checkbox',{attrs:{"value":scope.row.need_to_intro},on:{"input":(value) => _vm.$emit(
          'markIsNewTeacher',
          { isNewTeacher: value, teacherId: scope.row.new_teacher_user_id }
        )}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"30","label":"#"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 'addItem')?_c('span',[_vm._v(_vm._s(scope.$index + 1))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Teacher","width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addTeacher}},[_vm._v(" Add a teacher ")]):_c('div',[(scope.row.isEditing)?_c('TeacherSelector',{attrs:{"teachers":_vm.notYetSelectedTeachers,"value":scope.row.new_teacher_user_id || scope.row.teacher_user_id},on:{"change":teacher => {
              _vm.updateTeacher(scope.$index, {
                new_teacher: teacher,
                new_teacher_user_id: teacher.id,
                new_salary: teacher.in_person_salary || 0,
                new_salary_currency: teacher.in_person_currency || 'TWD',
                new_online_salary: teacher.online_salary || 0,
                new_online_salary_currency: teacher.online_currency || 'TWD'
              });
            }}}):_c('div',[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( scope.row.teacher.first_name, scope.row.teacher.last_name )}`)+" ")])],1)]}}])}),_c('el-table-column',{attrs:{"width":"140px","label":_vm.hasSomeEditing ? '' : '薪水',"prop":"salary"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('span'):_c('div',[(scope.row.isEditing)?_c('div'):_c('div',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol( _vm.isOnline ? scope.row.online_salary : scope.row.salary, _vm.isOnline ? scope.row.online_salary_currency : scope.row.salary_currency ))+"/hr ")])])]}}])}),_c('el-table-column',{attrs:{"width":"270px","prop":"online_salary"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('span'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(scope.row.type !== 'addItem' && _vm.hasSomeEditing),expression:"scope.row.type !== 'addItem' && hasSomeEditing"}],class:_vm.isOnline ? 'mainPrice' : ''},[(scope.row.isEditing)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('InputMoney',{attrs:{"money":scope.row.new_online_salary || scope.row.online_salary,"currency":scope.row.new_online_salary_currency || scope.row.online_salary_currency},on:{"changeMoney":salary => {
                _vm.updateTeacher(scope.$index, { new_online_salary: salary });
              },"changeCurrency":currency => {
                _vm.updateTeacher(scope.$index, {
                  new_online_salary_currency: currency
                });
              }}}),_vm._v(" /hr ")],1):_c('div',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol(scope.row.online_salary, scope.row.online_salary_currency))+"/hr ")])])]}}])},[_c('template',{slot:"header"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSomeEditing),expression:"hasSomeEditing"}]},[_vm._v(" "+_vm._s(`線上薪水`)+" "),(_vm.isOnline)?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("（主要薪水）")]):_vm._e()])])],2),_c('el-table-column',{attrs:{"width":"270px","prop":"salary"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('span'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(scope.row.type !== 'addItem' && _vm.hasSomeEditing),expression:"scope.row.type !== 'addItem' && hasSomeEditing"}],class:_vm.isOnline ? '' : 'mainPrice'},[(scope.row.isEditing)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('InputMoney',{attrs:{"money":scope.row.new_salary || scope.row.salary,"currency":scope.row.new_salary_currency || scope.row.salary_currency},on:{"changeMoney":salary => {
                _vm.updateTeacher(scope.$index, { new_salary: salary });
              },"changeCurrency":currency => {
                _vm.updateTeacher(scope.$index, {
                  new_salary_currency: currency
                });
              }}}),_vm._v(" /hr ")],1):_c('div',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol(scope.row.salary, scope.row.salary_currency))+"/hr ")])])]}}])},[_c('template',{slot:"header"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSomeEditing),expression:"hasSomeEditing"}]},[_vm._v(" "+_vm._s(`面對面薪水`)+" "),(!_vm.isOnline)?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("（主要薪水）")]):_vm._e()])])],2),_c('el-table-column',{key:_vm.hasSomeEditing,attrs:{"width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.isEditing)?_c('div',[_c('el-checkbox',{attrs:{"value":scope.row.is_update_teacher_salary},on:{"input":(is_update_teacher_salary) => _vm.updateTeacher(scope.$index, { is_update_teacher_salary })}})],1):_vm._e()]}}])},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.hasSomeEditing ? "更新老師薪水" : "")+" ")])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('span'):_c('div',[(scope.row.isEditing)?_c('div',[_c('el-radio-group',{attrs:{"value":scope.row.new_can_see_others > -1
                ? scope.row.new_can_see_others
                : scope.row.can_see_others},on:{"input":can_see_others => {
                _vm.updateTeacher(scope.$index, {
                  new_can_see_others: can_see_others
                });
              }}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("Yes")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("No")])],1)],1):_c('div',[_vm._v(_vm._s(scope.row.can_see_others ? "Yes" : "No"))])])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("See all lesson logs")]),_c('el-tooltip',{attrs:{"content":"如果是 Yes 的話就能看到其他老師的 Logs 或是 Reports，選 No 的話就看不見","placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon"},[_c('i',{staticClass:"fas fa-question-circle"})])])],1)],2),_c('el-table-column',{attrs:{"width":"100","label":_vm.$t('column.action')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 'addItem')?_c('div',[(scope.row.isEditing)?_c('div',[_c('el-tooltip',{attrs:{"value":true,"content":_vm.$t('message.remeberSave'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"check",staticClass:"action-icon",on:{"click":function($event){return _vm.saveTeacher(scope.$index)}}},[_c('i',{staticClass:"fas fa-check"})])]),_c('span',{key:"times",staticClass:"warning-icon",on:{"click":function($event){return _vm.closeEditTeacherMode(scope.row, scope.$index)}}},[_c('i',{staticClass:"fas fa-times"})])],1):_c('div',[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.openEditTeacherMode(scope.row.teacher_user_id)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{staticClass:"warning-icon",on:{"click":function($event){return _vm.closeEditTeacherMode({ teacher: null }, scope.$index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }