<template>
  <div>
    <el-dialog
      :title="
        student.requestIndex !== null && student.requestIndex > -1
          ? 'Edit student'
          : $t('bill.form.addStudent')
      "
      :visible.sync="addStudentVisible"
      width="60%"
    >
      <el-form label-width="150px">
        <el-form-item label="學生">
          <div
            v-if="
              student.requestIndex !== null &&
                student.requestIndex > -1 &&
                student.student
            "
          >
            {{
              userMethods.displayName(
                student.student.first_name,
                student.student.last_name
              )
            }}
          </div>
          <el-select
            v-else
            v-model="student.index"
            @change="selectStudent"
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(user, index) in allActiveStudents"
              :key="index"
              :value="index"
              :label="`${user.name}(${user.email})`"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            style="width: 100%;"
            class="select-test"
            v-model="student.index"
            @change="selectStudent"
            filterable
            placeholder="Search"
            no-data-text="請輸入姓名搜尋"
            :filter-method="filterCheckPerOptions"
          >
            <el-option
              v-for="(student, index) in allStudentsTop30"
              :key="index"
              :value="student.id"
              :label="`${student.name}(${student.email})`"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <template v-if="student.student_user_id > 0">
          <el-form-item label="報名表">
            <el-select
              v-model="student.student_class_ticket_id"
              style="width: 100%"
            >
              <el-option
                :value="null"
                label="請選擇對應報名表，如果沒有可以填寫下面信息后直接新增。"
              >
              </el-option>
              <el-option
                v-for="(student_class_ticket,
                index) in student.student_class_tickets
                  ? student.student_class_tickets
                  : []"
                :value="student_class_ticket.id"
                :key="index"
                :label="
                  `(${moment(
                    student_class_ticket.enrolled_session.updated_at
                  ).format('YYYY-MM-DD')}) ${helper.displayI18nText(
                    $i18n.locale,
                    student_class_ticket.enrolled_session.course_session.title
                  )}`
                "
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template>
            <el-form-item :label="`線上${$t('column.tuition')} (/hr)`">
              <InputMoney
                :money="
                  isNaN(student.new_online_fee)
                    ? student.online_fee
                    : student.new_online_fee
                "
                :currency="
                  student.new_online_fee_currency || student.online_fee_currency
                "
                @changeMoney="
                  online_fee => {
                    updateStudent({
                      new_online_fee: online_fee
                    });
                  }
                "
                @changeCurrency="
                  online_fee_currency => {
                    updateStudent({
                      new_online_fee_currency: online_fee_currency
                    });
                  }
                "
              />
            </el-form-item>
            <el-form-item :label="`面對面${$t('column.tuition')} (/hr)`">
              <InputMoney
                :money="isNaN(student.new_fee) ? student.fee : student.new_fee"
                :currency="student.new_fee_currency || student.fee_currency"
                @changeMoney="
                  fee => {
                    updateStudent({
                      new_fee: fee
                    });
                  }
                "
                @changeCurrency="
                  currency => {
                    updateStudent({
                      new_fee_currency: currency
                    });
                  }
                "
              />
            </el-form-item>
            <el-form-item label="是否要預付學費">
              <el-radio-group
                :value="
                  student.new_need_pay_first == null
                    ? student.need_pay_first
                    : student.new_need_pay_first
                "
                @input="
                  need_pay_first => {
                    updateStudent({
                      new_need_pay_first: need_pay_first
                    });
                  }
                "
              >
                <el-radio-button :label="1">Yes</el-radio-button>
                <el-radio-button :label="0">No</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="預設收信家長">
              <el-checkbox-group
                v-if="student.student.parents"
                v-model="student.default_parents_receivers"
              >
                <el-checkbox
                  v-for="(parent, index) in student.student.parents
                    ? student.student.parents
                    : []"
                  :label="parent.id"
                  :key="index"
                >
                  {{
                    `${userMethods.displayName(
                      parent.first_name,
                      parent.last_name
                    )}(${parent.email})`
                  }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </template>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStudentVisible = false">取 消</el-button>
        <el-button
          v-if="student.requestIndex !== null && student.requestIndex > -1"
          type="primary"
          @click="updateLessonRequestInfo(student.requestIndex)"
        >
          更 新
        </el-button>
        <el-button v-else type="primary" @click="addLessonRequest">
          儲 存
        </el-button>
      </span>
    </el-dialog>
    <el-table :key="isOnline" :data="lessonRequests" style="width: 100%">
      <el-table-column width="150" :label="$t('column.student')">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: {
                id: scope.row.student_user_id
              }
            }"
            >{{
              userMethods.displayName(
                scope.row.student.first_name,
                scope.row.student.last_name
              )
            }}</router-link
          >
        </template>
      </el-table-column>
      <template>
        <el-table-column width="300px">
          <template slot="header">
            <span>
              {{ `線上${$t("column.tuition")}` }}
              <span style="color: orange" v-if="isOnline">（主要學費）</span>
            </span>
          </template>
          <template slot-scope="scope">
            {{
              addCurrencySymbol(
                scope.row.online_fee,
                scope.row.online_fee_currency
              )
            }}/hr
          </template>
        </el-table-column>
        <el-table-column width="300px">
          <template slot="header">
            <span>
              {{ `面對面${$t("column.tuition")}` }}
              <span style="color: orange" v-if="!isOnline">（主要學費）</span>
            </span>
          </template>
          <template slot-scope="scope">
            {{ addCurrencySymbol(scope.row.fee, scope.row.fee_currency) }}/hr
          </template>
        </el-table-column>
        <el-table-column label="是否要預付學費">
          <template slot-scope="scope">
            {{ scope.row.need_pay_first ? "Yes" : "No" }}
          </template>
        </el-table-column>
        <el-table-column label="預設收信家長">
          <template slot-scope="scope">
            <div
              v-for="parent in scope.row.student.parents"
              :key="parent.id"
              class="mb-2"
            >
              <el-tag
                v-show="
                  scope.row.default_parents_receivers.indexOf(parent.id) > -1
                "
              >
                {{
                  `${userMethods.displayName(
                    parent.first_name,
                    parent.last_name
                  )} (${parent.email})`
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('column.action')">
          <template slot-scope="scope">
            <span class="action-icon" @click="openEditStudent(scope.$index)">
              <i class="fas fa-edit" />
            </span>
            <span class="warning-icon" @click="removeStudent(scope.$index)">
              <i class="fas fa-trash-alt" />
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="mt-3">
      <el-button type="primary" size="mini" @click="addNewStudent">
        <i class="fa fa-plus"></i> {{ $t("bill.form.addStudent") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@ivy-way/material";
import { user } from "@ivy-way/material";
import enrollmentApi from "@/apis/enrollment";
import arrangeApi from "@/apis/user";
import moneyMixin from "@/mixins/money";
import { InputMoney } from "@/components/input";

export default {
  name: "AddStudentTable",
  components: {
    // StudentSelector
    InputMoney
  },
  mixins: [moneyMixin],
  props: {
    originalLessonRequests: {
      type: Array,
      default: () => []
    },
    isOnline: {
      type: Boolean,
      default: false
    },
    subject_type: {
      type: String,
      default: ""
    }
  },
  watch: {
    originalLessonRequests() {
      this.lessonRequests = this.resetLessonRequest();
    }
  },
  computed: {
    helper() {
      return helper;
    },
    userMethods() {
      return user;
    },
    course_session_id() {
      let course_session_id = 1;
      if (this.subject_type === "counseling") {
        if (this.isOnline) {
          course_session_id = 6;
        } else {
          course_session_id = 4;
        }
      } else if (this.subject_type === "privateLessons") {
        if (this.isOnline) {
          course_session_id = 1;
        } else {
          course_session_id = 2;
        }
      }
      return course_session_id;
    },
    editing() {
      return this.lessonRequests.some(({ isEditing }) => isEditing);
    },
    defaultFee() {
      return {
        need_pay_first: 0,
        new_need_pay_first: 0,
        fee: 2850,
        new_fee: 2850,
        fee_currency: "TWD",
        new_fee_currency: "TWD",
        online_fee: 1950,
        new_online_fee: 1950,
        online_fee_currency: "TWD",
        new_online_fee_currency: "TWD"
      };
    }
  },
  data() {
    return {
      allStudentsTop30: [],
      moment,
      allActiveStudents: [],
      lessonRequests: [],
      addStudentVisible: false,
      student: {
        requestIndex: null,
        index: null,
        student_class_tickets: [],
        student_class_ticket_id: null,
        student_user_id: null,
        student: null,
        new_student: null,
        default_parents_receivers: []
      }
    };
  },
  created() {
    this.lessonRequests = this.resetLessonRequest();
    this.fetchActiveStudents();
  },
  methods: {
    filterCheckPerOptions(query) {
      console.log(query);
      // query是输入框中的检索条件
      if (query) {
        let arr = this.allActiveStudents.filter(item => {
          if (
            item.first_name &&
            item.first_name.toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
          if (
            item.last_name &&
            item.last_name.toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
          if (
            `${item.first_name} ${item.last_name}`
              .toLowerCase()
              .includes(query.toLowerCase())
          ) {
            return true;
          }
          if (
            `${item.last_name}${item.first_name}`
              .toLowerCase()
              .includes(query.toLowerCase())
          ) {
            return true;
          }
          if (`${item.email}`.toLowerCase().includes(query.toLowerCase())) {
            return true;
          }
          return false;
        });
        // 根据检索条件筛选出来的选项，只取前30条
        if (arr.length > 30) {
          arr = arr.slice(0, 30);
        }
        // 清空之前的选项
        this.allStudentsTop30.splice(0, this.allStudentsTop30.length);
        this.allStudentsTop30.push(...arr);
        console.log(this.allStudentsTop30);
      } else {
        this.allStudentsTop30 = [];
      }
      // chosen表示已被选择的选项，添加这一部分主要是为了回显，避免选择框中直接出现用户id
      // const chosen = this.getChosenItemsArr();
      // // 检索项 + 已选项的并集
      // const result = [...arr, ...chosen.filter(item => !arr.includes(item))];
      // if (arr.length > 30) {
      //   this.allStudentsTop30.push(...result);
      // } else {
      //   this.allStudentsTop30.push(...result);
      // }
    },
    getChosenItemsArr() {
      // 获取已被选中的人员
      const items = [];
      for (let i = 0; i < this.allActiveStudents.length; i++) {
        if (
          [this.student.student_user_id].indexOf(
            this.allActiveStudents[i].id
          ) >= 0 &&
          items.indexOf(this.allActiveStudents[i]) < 0
        ) {
          items.push(this.allActiveStudents[i]);
        }
      }
      return items;
    },
    resetLessonRequest() {
      let lessonRequests = [];
      this.originalLessonRequests.forEach((lessonRequest, index) => {
        lessonRequests.push({
          ...lessonRequest,
          requestIndex: index,
          new_need_pay_first: lessonRequest.need_pay_first,
          new_fee: lessonRequest.fee,
          new_fee_currency: lessonRequest.fee_currency,
          new_online_fee: lessonRequest.online_fee,
          new_online_fee_currency: lessonRequest.online_fee_currency
        });
      });
      return lessonRequests;
    },
    addLessonRequest() {
      if (this.checkSameStudent()) {
        this.lessonRequests.push({
          ...this.student,
          requestIndex: this.lessonRequests.length,
          need_pay_first: this.student.new_need_pay_first,
          fee: this.student.new_fee,
          fee_currency: this.student.new_fee_currency,
          online_fee: this.student.new_online_fee,
          online_fee_currency: this.student.new_online_fee_currency
        });
        this.addStudentVisible = false;
        this.$emit("saveLessonRequest", [...this.lessonRequests]);
      }
    },
    async fetchActiveStudents() {
      let students = await arrangeApi.searchStudents();

      if (this.$route.query.enrolled_session_id) {
        const {
          student_profile: {
            basic_info: { id }
          }
        } = await enrollmentApi.fetch({
          id: this.$route.query.enrolled_session_id
        });

        students = students.filter(student => student.id === id);
      }

      this.allActiveStudents = students;
    },
    addNewStudent() {
      this.addStudentVisible = true;
      this.student = {
        action_mode: "A",
        requestIndex: null,
        index: null,
        student_class_tickets: [],
        student_class_ticket_id: null,
        student_user_id: null,
        student: null,
        new_student: null,
        ...this.defaultFee,
        default_parents_receivers: []
      };
    },
    async selectStudent(index) {
      let student = this.allActiveStudents[index];
      this.student.student_user_id = student.id;
      this.student.student = student;
      this.student.new_student = { ...this.student.student };
      const res = await enrollmentApi.getStudentClassTicketsPaidButNoPlacement({
        student_user_id: student.id,
        course_session_id: this.course_session_id
      });
      this.student.student_class_ticket_id = null;
      this.student["student_class_tickets"] = res.student_class_tickets;
      this.student.default_parents_receivers = [];
      if (
        this.student.student.parents &&
        this.student.student.parents.length > 0
      ) {
        this.student.student.parents.forEach(parent => {
          this.student.default_parents_receivers.push(parent.id);
        });
      }
    },
    updateStudent(data) {
      this.student = {
        ...this.student,
        ...data
      };
    },
    updateLessonRequestInfo(targetIndex) {
      this.lessonRequests[targetIndex] = {
        ...this.student,
        need_pay_first: this.student.new_need_pay_first,
        fee: this.student.new_fee,
        fee_currency: this.student.new_fee_currency,
        online_fee: this.student.new_online_fee,
        online_fee_currency: this.student.new_online_fee_currency
      };
      this.addStudentVisible = false;
      this.$emit("saveLessonRequest", [...this.lessonRequests]);
    },
    // saveLessonRequest(targetIndex) {
    //   const isExist = new_user_id =>
    //     this.lessonRequests
    //       .filter(lessonRequest => !lessonRequest.isEditing)
    //       .some(lessonRequest => lessonRequest.student_user_id === new_user_id);
    //   let targetStudent = this.lessonRequests[targetIndex];
    //   if (!targetStudent.student && !targetStudent.new_student) {
    //     this.$message.error(this.$t("message.pleaseSelectStudent"));
    //     return;
    //   }

    //   if (isExist(targetStudent.new_student_user_id)) {
    //     this.$message.error("已有相同學生！");
    //     return;
    //   }
    //   this.lessonRequests = this.lessonRequests.map((lessonRequest, index) => {
    //     if (index !== targetIndex) return lessonRequest;
    //     return {
    //       ...lessonRequest,
    //       isEditing: false,
    //       student: targetStudent.new_student || targetStudent.student,
    //       student_user_id:
    //         targetStudent.new_student_user_id || targetStudent.student_user_id,
    //       need_pay_first:
    //         targetStudent.new_need_pay_first || targetStudent.need_pay_first,
    //       fee: targetStudent.new_fee || targetStudent.fee,
    //       fee_currency:
    //         targetStudent.new_fee_currency || targetStudent.fee_currency,
    //       online_fee: targetStudent.new_online_fee || targetStudent.online_fee,
    //       online_fee_currency:
    //         targetStudent.new_online_fee_currency ||
    //         targetStudent.online_fee_currency
    //     };
    //   });
    //   this.$emit("saveLessonRequest", [...this.lessonRequests]);
    // },
    async openEditStudent(index) {
      this.studentIsEdit = true;
      this.addStudentVisible = true;
      this.student = JSON.parse(JSON.stringify(this.lessonRequests[index]));
      const res = await enrollmentApi.getStudentClassTicketsPaidButNoPlacement({
        student_user_id: this.student.student.id,
        course_session_id: this.course_session_id,
        student_class_ticket_id: this.student.student_class_ticket_id
      });
      // this.student.student_class_ticket_id = null;
      this.student = {
        ...this.student,
        student_user_id: this.student.student.id,
        student_class_tickets: res.student_class_tickets
      };
    },
    checkSameStudent() {
      let isDifferent = true;
      let isDifferentNum = 0;
      this.lessonRequests.forEach(request => {
        if (this.student.student_user_id === request.student_user_id) {
          isDifferentNum++;
        }
      });
      if (isDifferentNum > 0) {
        isDifferent = false;
        this.$message.error("已有相同學生！請重新選擇學生");
      }
      return isDifferent;
    },
    async removeStudent(targetIndex) {
      if (
        this.lessonRequests[targetIndex].new_student_user_id ||
        this.lessonRequests[targetIndex].student_user_id
      ) {
        try {
          await this.$confirm(
            this.$t("message.areYouSure"),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        } catch (e) {
          return false;
        }
      }
      this.lessonRequests = this.lessonRequests.filter(
        (lessonRequest, index) => index !== targetIndex
      );
      this.$emit("saveLessonRequest", [...this.lessonRequests]);
    }
  }
};
</script>

<style scoped>
.mainPrice {
  border: 2px solid orange;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  width: max-content;
}

.inlineFormItem {
  margin-bottom: 0;
}
</style>
