<template>
  <div>
    <el-form-item>
      <template slot="label">
        <span>管理員筆記</span>
        <el-tooltip
          class="item"
          content="Only admin can see this private notes."
          placement="top"
          popper-class="tooltipColor"
        >
          <i class="el-icon-lock" />
        </el-tooltip>
      </template>
      <el-input
        type="textarea"
        :value="admin_note"
        @input="
          value => {
            setValue({ admin_note: value });
          }
        "
      />
    </el-form-item>
    <el-form-item>
      <template slot="label">
        <span>老師筆記</span>
        <el-tooltip
          class="item"
          content="Only admin and the teacher can see this private notes."
          placement="top"
          popper-class="tooltipColor"
        >
          <i class="el-icon-lock" />
        </el-tooltip>
      </template>
      <el-input
        type="textarea"
        :value="teacher_note"
        @input="
          value => {
            setValue({ teacher_note: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "Note",
  props: {
    admin_note: String,
    teacher_note: String
  },
  methods: {
    setValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>

<style scoped></style>
