<template>
  <el-input-number
    :size="size"
    :style="`width: ${width || 80}px`"
    controls-position="right"
    :value="value"
    @input="change"
    @blur="computeDurationMinutes"
    :step="step"
    :min="0"
    :max="59"
  />
</template>

<script>
export default {
  props: ["value", "change", "size", "width", "step"],
  methods: {
    computeDurationMinutes() {
      const MINUTE_STEP = 5;
      const durationMinutes = this.value / MINUTE_STEP;
      const [multiplier, carry] = [
        Math.floor(durationMinutes),
        (String(durationMinutes).split(".")[1] || [0])[0]
      ];
      let computedMinutes = null;
      if (carry > 5) {
        computedMinutes = MINUTE_STEP * (multiplier + 1);
      } else {
        computedMinutes = MINUTE_STEP * multiplier;
      }
      this.change(computedMinutes >= 60 ? 0 : computedMinutes);
    }
  }
};
</script>
