<template>
  <el-dialog
    title="Class Type"
    :visible.sync="visible"
    width="30%"
    :before-close="() => $emit('toggleDialogVisible', false)"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div class="content">
      <div
        :class="`pointer options ${isOnline === 1 ? 'selectedOptions' : ''}`"
        style="margin-right: 8px"
        @click="isOnline = 1"
      >
        <span class="icon">
          <i class="fa fa-globe" />
        </span>
        <p>Online</p>
      </div>
      <div
        :class="`pointer options ${isOnline === 0 ? 'selectedOptions' : ''}`"
        style="margin-left: 8px"
        @click="isOnline = 0"
    >
        <span class="icon">
          <i class="fas fa-building" />
        </span>
        <p>In-person</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="() => $emit('submit', isOnline)">
        {{ $t("button.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    defaultIsOnline: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      isOnline: 1
    };
  },
  watch: {
    defaultIsOnline() {
      this.isOnline = this.defaultIsOnline;
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
}

.options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 4px solid #bababa;
    border-radius: 8px;
    width: 1000px;
    transition: border 0.2s ease;

    .icon {
        color: #bababa;
        font-size: 84px;
        transition: color 0.2s ease;
    }

    p {
        color: #bababa;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}

.selectedOptions {
    border: 4px solid #68b488;
    transition: border 0.2s ease;

    .icon {
        color: #68b488;
        transition: color 0.2s ease;
    }

    p {
        font-weight: bolder;
        color: #68b488;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}
</style>
