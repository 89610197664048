<template>
  <el-table :key="isOnline" style="width: 100%" :data="attachAddButton(teachers)" border>
    <el-table-column width="100" label="是否發送配對成功信">
      <template slot-scope="scope">
        <el-checkbox
          v-if="scope.row.type !== 'addItem'"
          :value="scope.row.need_to_intro"
          @input="(value) => $emit(
            'markIsNewTeacher',
            { isNewTeacher: value, teacherId: scope.row.new_teacher_user_id }
          )"
        />
      </template>
    </el-table-column>
    <el-table-column width="30" label="#">
      <template slot-scope="scope">
        <span v-if="scope.row.type !== 'addItem'">{{ scope.$index + 1 }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Teacher" width="240px">
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.type === 'addItem'"
          type="primary"
          size="mini"
          @click="addTeacher"
        >
          Add a teacher
        </el-button>
        <div v-else>
          <TeacherSelector
            v-if="scope.row.isEditing"
            :teachers="notYetSelectedTeachers"
            :value="scope.row.new_teacher_user_id || scope.row.teacher_user_id"
            @change="
              teacher => {
                updateTeacher(scope.$index, {
                  new_teacher: teacher,
                  new_teacher_user_id: teacher.id,
                  new_salary: teacher.in_person_salary || 0,
                  new_salary_currency: teacher.in_person_currency || 'TWD',
                  new_online_salary: teacher.online_salary || 0,
                  new_online_salary_currency: teacher.online_currency || 'TWD'
                });
              }
            "
          />
          <div v-else>
            {{
              `${userMethods.displayName(
                scope.row.teacher.first_name,
                scope.row.teacher.last_name
              )}`
            }}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column width="140px" :label="hasSomeEditing ? '' : '薪水'" prop="salary">
      <template slot-scope="scope">
        <span v-if="scope.row.type === 'addItem'" />
        <div v-else>
          <div v-if="scope.row.isEditing" />
          <div v-else>
            {{
              addCurrencySymbol(
                isOnline ? scope.row.online_salary : scope.row.salary,
                isOnline ? scope.row.online_salary_currency : scope.row.salary_currency
              )
            }}/hr
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column width="270px" prop="online_salary">
      <template slot="header">
        <span v-show="hasSomeEditing">
          {{ `線上薪水` }}
          <span style="color: orange" v-if="isOnline">（主要薪水）</span>
        </span>
      </template>
      <template slot-scope="scope">
        <span v-if="scope.row.type === 'addItem'" />
        <div
          v-show="scope.row.type !== 'addItem' && hasSomeEditing"
          :class="isOnline ? 'mainPrice' : ''"
        >
          <div
            v-if="scope.row.isEditing"
            style="display: flex; align-items: center;"
          >
            <InputMoney
              :money="scope.row.new_online_salary || scope.row.online_salary"
              :currency="scope.row.new_online_salary_currency || scope.row.online_salary_currency"
              @changeMoney="
                salary => {
                  updateTeacher(scope.$index, { new_online_salary: salary });
                }
              "
              @changeCurrency="
                currency => {
                  updateTeacher(scope.$index, {
                    new_online_salary_currency: currency
                  });
                }
              "
            />
            /hr
          </div>
          <div v-else>
            {{ addCurrencySymbol(scope.row.online_salary, scope.row.online_salary_currency) }}/hr
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column width="270px" prop="salary">
      <template slot="header">
        <span v-show="hasSomeEditing">
          {{ `面對面薪水` }}
          <span style="color: orange" v-if="!isOnline">（主要薪水）</span>
        </span>
      </template>
      <template slot-scope="scope">
        <span v-if="scope.row.type === 'addItem'" />
        <div 
          v-show="scope.row.type !== 'addItem' && hasSomeEditing"
          :class="isOnline ? '' : 'mainPrice'"
        >
          <div
            v-if="scope.row.isEditing"
            style="display: flex; align-items: center;"
          >
            <InputMoney
              :money="scope.row.new_salary || scope.row.salary"
              :currency="scope.row.new_salary_currency || scope.row.salary_currency"
              @changeMoney="
                salary => {
                  updateTeacher(scope.$index, { new_salary: salary });
                }
              "
              @changeCurrency="
                currency => {
                  updateTeacher(scope.$index, {
                    new_salary_currency: currency
                  });
                }
              "
            />
            /hr
          </div>
          <div v-else>
            {{
              addCurrencySymbol(scope.row.salary, scope.row.salary_currency)
            }}/hr
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column :key="hasSomeEditing" width="100px" align="center">
      <template slot="header">
        {{ hasSomeEditing ? "更新老師薪水" : "" }}
      </template>
      <template slot-scope="scope">
        <div v-if="scope.row.isEditing">
          <el-checkbox
            :value="scope.row.is_update_teacher_salary"
            @input="(is_update_teacher_salary) => updateTeacher(scope.$index, { is_update_teacher_salary })"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span>See all lesson logs</span>
        <el-tooltip
          content="如果是 Yes 的話就能看到其他老師的 Logs 或是 Reports，選 No 的話就看不見"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </template>
      <template slot-scope="scope">
        <span v-if="scope.row.type === 'addItem'" />
        <div v-else>
          <div v-if="scope.row.isEditing">
            <el-radio-group
              :value="
                scope.row.new_can_see_others > -1
                  ? scope.row.new_can_see_others
                  : scope.row.can_see_others
              "
              @input="
                can_see_others => {
                  updateTeacher(scope.$index, {
                    new_can_see_others: can_see_others
                  });
                }
              "
            >
              <el-radio :label="1">Yes</el-radio>
              <el-radio :label="0">No</el-radio>
            </el-radio-group>
          </div>
          <div v-else>{{ scope.row.can_see_others ? "Yes" : "No" }}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column width="100" :label="$t('column.action')">
      <template slot-scope="scope">
        <div v-if="scope.row.type !== 'addItem'">
          <div v-if="scope.row.isEditing">
            <el-tooltip
              :value="true"
              :content="$t('message.remeberSave')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                key="check"
                class="action-icon"
                @click="saveTeacher(scope.$index)"
              >
                <i class="fas fa-check" />
              </span>
            </el-tooltip>
            <span
              key="times"
              class="warning-icon"
              @click="closeEditTeacherMode(scope.row, scope.$index)"
            >
              <i class="fas fa-times" />
            </span>
          </div>
          <div v-else>
            <span
              class="action-icon"
              @click="openEditTeacherMode(scope.row.teacher_user_id)"
            >
              <i class="fas fa-edit" />
            </span>
            <span
              class="warning-icon"
              @click="closeEditTeacherMode({ teacher: null }, scope.$index)"
            >
              <i class="fas fa-trash-alt" />
            </span>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { user } from "@ivy-way/material";
import profileApi from "@/apis/profile";
import moneyMixin from "@/mixins/money";
import { TeacherSelector } from "@/components/selector";
import { InputMoney } from "@/components/input";

class LinkTeacher {
  constructor(teacher) {
    this.teacher_user_id = teacher.teacher_user_id || null;
    this.salary_currency = teacher.salary_currency || "TWD";
    this.salary = teacher.salary || 0;
    this.salary_unit = teacher.salary_unit || 3;
    this.online_salary = teacher.online_salary || 0,
    this.online_salary_currency = teacher.online_salary_currency|| "TWD",
    this.online_salary_unit = teacher.online_salary_unit || 3,
    this.can_see_others = teacher.can_see_others || 1;
  }
}

export default {
  name: "AddTeacherTable",
  components: {
    TeacherSelector,
    InputMoney
  },
  mixins: [moneyMixin],
  props: {
    originalTeachers: {
      type: Array,
      default: () => []
    },
    isOnline: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    originalTeachers() {
      this.teachers = [...this.originalTeachers];
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    hasSomeEditing() {
      return this.teachers.some(({ isEditing }) => isEditing);
    },
    notYetSelectedTeachers() {
      return this.activeTeachers;
    }
  },
  data() {
    return {
      teachers: [],
      activeTeachers: []
    };
  },
  async created() {
    this.teachers = [...this.originalTeachers];
    const teachersRes = await profileApi.fetchTeachers();
    this.activeTeachers = teachersRes.users;
  },
  methods: {
    attachAddButton(originalTeachers) {
      return [...originalTeachers, { type: "addItem" }];
    },
    addTeacher() {
      this.teachers = [
        ...this.teachers,
        {
          isEditing: true,
          ...new LinkTeacher({})
        }
      ];
      this.$emit("saveTeachers", [...this.teachers]);
      this.$emit("addTeacher", [...this.teachers]);
    },
    updateTeacher(targetIndex, newTeacher) {
      this.teachers[targetIndex] = {
        ...this.teachers[targetIndex],
        ...newTeacher
      };
      this.teachers = [...this.teachers];
    },
    saveTeacher(targetIndex) {
      const isExist = new_user_id =>
        this.teachers
          .filter(teacher => !teacher.isEditing)
          .some(teacher => teacher.teacher_user_id === new_user_id);

      let targetTeacher = this.teachers[targetIndex];
      if (!targetTeacher.teacher && !targetTeacher.new_teacher) {
        this.$message.error(this.$t("message.pleaseSelectTeacher"));
        return;
      }
      if (isExist(targetTeacher.new_teacher_user_id)) {
        this.$message.error("已有相同老師！");
        return;
      }

      this.teachers = this.teachers.map((teacher, index) => {
        if (index !== targetIndex) return teacher;
        let teacherUser = teacher.new_teacher || teacher.teacher;

        if (teacher.new_teacher) {
          let counseling_bio = teacher.new_teacher.counseling_bio;
          if (typeof counseling_bio === "string") {
            counseling_bio = JSON.parse(teacher.new_teacher.counseling_bio) || { en: "", tw: "", cn: "" };
          };
          let tutoring_bio = teacher.new_teacher.tutoring_bio;
          if (typeof tutoring_bio === "string") {
            tutoring_bio = JSON.parse(teacher.new_teacher.tutoring_bio) || { en: "", tw: "", cn: "" };
          };
          teacherUser = {
            ...teacher.new_teacher,
            teacher_profile: { counseling_bio, tutoring_bio },
            counseling_bio, tutoring_bio
          };
        }

        return {
          ...teacher,
          isEditing: false,
          teacher: { ...teacherUser },
          new_teacher: { ...teacherUser },
          teacher_user_id:
            teacher.new_teacher_user_id || teacher.teacher_user_id,
          can_see_others:
            teacher.new_can_see_others >-1 ? teacher.new_can_see_others : teacher.can_see_others,
          salary: teacher.new_salary || teacher.salary,
          salary_currency:
            teacher.new_salary_currency || teacher.salary_currency,
          online_salary: teacher.new_online_salary || teacher.online_salary,
          online_salary_currency:
            teacher.new_online_salary_currency || teacher.online_salary_currency
        };
      });
      this.$emit("saveTeachers", [...this.teachers]);
    },
    openEditTeacherMode(teacherUserId) {
      this.teachers = this.teachers.map(teacher => {
        if (teacher.teacher_user_id !== teacherUserId)
          return teacher;
        return { ...teacher, isEditing: true };
      });
      this.$emit("saveTeachers", [...this.teachers]);
    },
    async closeEditTeacherMode(targetTeacher, targetIndex) {
      if (!targetTeacher.teacher) {
        if (targetTeacher.new_teacher_user_id || targetTeacher.teacher_user_id) {
          try {
            await this.$confirm(
              this.$t("message.areYouSure"),
              this.$t("message.notice"),
              {
                confirmButtonText: this.$t("message.continue"),
                cancelButtonText: this.$t("message.cancel"),
                type: "warning"
              }
            );
          } catch (e) {
            return false;
          }
        }

        this.teachers = this.teachers.filter((teacher, index) => index !== targetIndex);
      } else {
        this.teachers = this.teachers.map(teacher => {
          if (teacher.teacher_user_id !== targetTeacher.teacher_user_id)
            return teacher;
          return {
            ...teacher,
            isEditing: false
          };
        });
      }
      this.$emit("saveTeachers", [...this.teachers]);
    }
  }
};
</script>

<style scoped>
</style>
